// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

const sliderHero = document.querySelector(".first-screen-swiper");

if (sliderHero) {
    const sliderItem = sliderHero.querySelectorAll(".first-screen-swiper .swiper-slide");

    if (sliderItem.length > 1) {
        const swiper = new Swiper(sliderHero, {
            // effect: "coverflow",
            // coverflowEffect: {
            //     rotate: 180,
            //     slideShadows: false,
            // },
            direction: "horizontal",
            loop: false,
            autoHeight: true,
            autoplay: {
                delay: 4000,
            },
            pagination: {
                el: ".swiper-pagination",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }
}

$(function() {
  $("a[href^='#']").click(function() {
      var _href = $(this).attr("href");
      $("html, body").animate({
          scrollTop: $(_href).offset().top + "px"
      });
      return false;
  });
});
